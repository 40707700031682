var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-dashboard',{attrs:{"title":_vm.title}},[_c('v-container',{staticClass:"pa-0 ma-0 fill-container-height",attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"width":"500","height":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-8"},[_vm._l((Object.keys(_vm.contactHeaders)),function(item,index){return _c('span',{key:index,staticClass:"pb-4"},[(_vm.chosenContact[item])?_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.contactHeaders[item])+_vm._s(_vm.chosenContact[item])+" ")]):_vm._e()],1)}),(!Object.keys(_vm.chosenContact).length)?_c('v-row',[_vm._v(_vm._s(_vm.noData))]):_vm._e()],2)],1),_c('v-row',{staticClass:"pa-0 ma-0 mt-4",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"10","sm":"11"}},[(_vm.areChartsReady)?_c('statistics-charts'):_c('v-card',[_c('v-card-subtitle',[_c('loader-animation',{attrs:{"size":15}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.texts.loadingCharts))])],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"ma-0 mb-12 pa-0 mt-4",attrs:{"headers":_vm.headers,"items":_vm.getAppStatistics,"items-per-page":_vm.itemsPerPage,"custom-sort":_vm.customSort,"id":"statistics-data-table"},scopedSlots:_vm._u([{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.link))])]}},{key:"item.information",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":_vm.getAppStatisticsFetching},on:{"click":function($event){return _vm.getContact(item.id)}}},[_vm._v(" mdi-information-outline ")])]}},{key:"item.balances",fn:function(ref){
var item = ref.item;
return _vm._l((item.balances),function(element,index){return _c('v-row',{key:index,staticClass:"mx-0 my-2 px-0"},[_c('div',[_c('span',[_vm._v(_vm._s(((element.currency.toUpperCase()) + ":"))+" ")]),_c('span',[_vm._v(_vm._s(element.amount.toLocaleString()))])])])})}},{key:"item.more",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("" + _vm.linkPath + (item.link))}},[_c('v-btn',{staticClass:"text-none white--text",attrs:{"color":_vm.$mainColor}},[_vm._v(_vm._s(_vm.texts.buttonMore))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }