<template>
  <component-with-measurement-date class="py-4" :date="measurementDates[activeTab]">
    <v-card-subtitle class="font-weight-bold">
      {{ titles.appStatistics }}
    </v-card-subtitle>
    <v-tabs v-model="activeTab" class="pl-4" show-arrows>
      <v-tab class="text-none">{{ tabText('revenue') }}</v-tab>
      <v-tab class="text-none">{{ tabText('transactionsCount') }}</v-tab>
      <v-tab class="text-none">{{ tabText('transactionsHours') }}</v-tab>
      <v-tab class="text-none" :disabled="!isFilledData(amountsTypesChartData).length">
        {{ tabText('amountsTypes') }}
      </v-tab>
      <v-tab class="text-none" :disabled="!isFilledData(osChartData).length">{{ tabText('os') }}</v-tab>
      <v-tab class="text-none" :disabled="!isFilledData(usersPhonesModelsData).length">
        {{ tabText('phonesModels') }}
      </v-tab>

      <v-tab class="text-none" :disabled="!paymentProcessChartData[0].values.length">
        <!-- We are prepared for more operators in future, so it is neccessary to check only one of them - first -->
        {{ tabText('paymentsProcess') }}
      </v-tab>
      <v-tab class="text-none" :disabled="!isFilledData(avgPaymentTimeChartData).length">
        {{ tabText('avgPaymentTime') }}
      </v-tab>
    </v-tabs>

    <v-row class="ma-0 pa-0"
           :justify="getRowJustify"
           align="center">

      <v-col cols="6" v-if="isTransactionalHoursTab" class="ma-0 pa-0 mt-2">
        <v-row class="ma-0 pa-0">
          <v-col cols="6">
            <date-picker
                @change-date="(e) => changeDate('startDate', e)"
                :title="datePickers.startDateTitle"
                :init-date="datePickers.startDate"
                :max-date="datePickers.endDate"
            >
            </date-picker>
          </v-col>

          <v-col cols="6">
            <date-picker
                @change-date="(e) => changeDate('endDate', e)"
                :title="datePickers.endDateTitle"
                :init-date="datePickers.endDate"
            >
            </date-picker>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center" class="pa-0 ma-0" v-if="isPaymentOperatorTab">
      <funnel-chart-carousel
          :carousel-items="paymentProcessChartData"
      ></funnel-chart-carousel>
    </v-row>

    <div v-if="isRevenueTab">
      <chart
          :tooltip="tooltipMessage"
          key="revenue-chart"
          v-if="isFilledData(revenueChartData).length"
          :type="columnChart"
          :data="revenueChartData"
          :options="verticalColumnChartOptions"
      ></chart>
    </div>
    <div v-if="isTransactionalCountTab">
      <chart
          :tooltip="tooltipMessage"
          key="transactional-count-chart"
          v-if="isFilledData(transactionalCountChartData).length"
          :type="columnChart"
          :data="transactionalCountChartData"
          :options="verticalColumnChartOptions"
      >
      </chart>
    </div>
    <div v-if="isTransactionalHoursTab">
      <chart
          :tooltip="tooltipMessage"
          key="transactional-hours-chart"
          :type="columnChart"
          :data="transactionalHoursChartData"
          :options="hoursChartOptions"
      >
      </chart>
    </div>

    <div v-if="isAmountsTypesTab" class="flex-centered">
      <chart class="mb-4"
               :tooltip="tooltipMessage"
               :type="pieChart"
               :data="amountsTypesChartData"
               :options="pieChartOptions"
      >
      </chart>
    </div>
    <v-row v-if="isOSTab" class="justify-center mt-0">
      <chart
          :tooltip="tooltipMessage"
          v-if="isFilledData(osChartData).length"
          :type="pieChart"
          :data="osChartData"
          :options="pieChartOptions"
      >
      </chart>
    </v-row>
    <v-row v-if="isUsersPhonesModelsTab" class="justify-center">
      <chart
          :tooltip="tooltipMessage"
          :type="pieChart"
          :data="usersPhonesModelsData"
          :options="pieChartOptions"
      >
      </chart>
    </v-row>
    <average-payment-time-chart
      v-if="isAvgPaymentTimeTab"
      :tooltip="tooltipMessage"
      :chart-type="avgPaymentTimePickerType"
      :data="avgPaymentTimeChartData"
      @changeChartType="changeAvgPaymentTimePickerType"
      @changeDateRange="changeAvgPaymentTimeDate"
    />
  </component-with-measurement-date>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {
  currencies,
  transactionsChartHeaders,
  googleChartTypes,
  revenueChartHeaders,
  tooltips,
  amountTypes,
  amountsTypesHeaders,
  amountsTypesImplementationDate,
  osChartHeaders,
  prefixForTransactions,
  usersPhonesModelsHeaders,
  blueMediaPaymentsProcessChartHeaders,
  sectionsTitles,
  paymentOperatorsTitles,
  avgPaymentTimeHeaders, dateRangeType
} from '@/utils/macros/variables'
import externalChartsMixin from '@/mixins/components/external-charts'
import DatePicker from "@/components/dashboard/collection-evidence/DatePicker";
import connectedChartsMixin from '@/mixins/components/connected-charts'
import getOSCharMixin from '@/mixins/google-analytics/global-os-chart'
import FunnelChartCarousel from "@/components/dashboard/FunnelChartCarousel";
import ComponentWithMeasurementDate from "@/components/dashboard/ComponentWithMeasurementDate";
import AveragePaymentTimeChart from "@/components/dashboard/charts/AveragePaymentTimeChart";
import AveragePaymentTimeChartMixin from '@/mixins/components/average-payment-time-chart'
import Chart from "@/components/dashboard/charts/chart";

export default {
  name: "Charts",
  mixins: [externalChartsMixin, connectedChartsMixin, getOSCharMixin, AveragePaymentTimeChartMixin],
  components: {
    Chart,
    AveragePaymentTimeChart,
    DatePicker,
    FunnelChartCarousel,
    ComponentWithMeasurementDate
  },
  data: () => ({
    ...googleChartTypes,
    funnelChart: {
      labels: blueMediaPaymentsProcessChartHeaders,
      colors: ['rgb(51, 102, 204)'],
      values: [4, 3, 1]
    },
    carouselController: 0,
    titles: {
      appStatistics: 'App statistics'
    },
    measurementDates: { // RECOGNISE BY ACTIVE TAB INDEX
      3: '26-01-2022',
      4: '03-02-2022',
      5: '03-02-2022',
      6: '09-02-2022'
    },
    tabsText: {
      revenue: {
        text: 'Revenue ' + prefixForTransactions,
        tooltip: tooltips.globalRevenueChart
      },
      transactionsCount: {
        text: 'Count ' + prefixForTransactions,
        tooltip: tooltips.globalTransactionsCount
      },
      transactionsHours: {
        text: 'Hours ' + prefixForTransactions,
        tooltip: tooltips.globalTransactionsCountPerHours
      },
      amountsTypes: {
        text: 'Donation input vs button',
        tooltip: tooltips.globalAmountsTypesChart,
        additionalInfo: amountsTypesImplementationDate
      },
      os: {
        text: 'Operating Systems',
        tooltip: tooltips.globalOSChart
      },
      paymentsProcess: {
        text: sectionsTitles.paymentsProcess,
        tooltip: tooltips.paymentsProcess
      },
      transactions: 'Transactions',
      phonesModels: {
        text: 'Users phone models',
        tooltip: tooltips.phonesModelsChart
      },
      avgPaymentTime: {
        text: 'Average payment time',
        tooltip: tooltips.avgPaymentTime
      }
    },
    avgPaymentTimePickerType: dateRangeType.date,
    phonesModelsNotDefined: '(not set)',
    phonesModelsToExclude: ['desktop'],
    activeTab: 0,
    bigCollectionsForRevenue: [revenueChartHeaders],
    bigCollectionsForTransactions: [transactionsChartHeaders],
    smallCollectionsForRevenue: [revenueChartHeaders],
    smallCollectionsForTransactions: [transactionsChartHeaders],
    revenueSeparatorAmount: 150,
    transactionsSeparatorAmount: 40,
    revenueChartData: [revenueChartHeaders],
    transactionalCountChartData: [revenueChartHeaders],
    amountsTypesChartData: [amountsTypesHeaders],
    osChartData: [osChartHeaders],
    usersPhonesModelsData: [usersPhonesModelsHeaders],
    avgPaymentTimeChartData: [avgPaymentTimeHeaders]
  }),
  beforeMount() {
    this.activeTab = this.getAllFoundationsTab
    this.prepareRevenueChartData()
    this.prepareTransactionsCountChartData()
    this.prepareTransactionsHoursChartData()
    this.prepareAmountTypesChartData()
    this.prepareOSChartData()
    this.preparePhonesModelsChartData()
    this.prepareBlueMediaDonationProcessChart(this.getGlobalBlueMediaDonationProcess)
    this.prepareAvgPaymentTimeChartData()
  },
  computed: {
    ...mapGetters('components', ['getAllFoundationsTab']),
    ...mapGetters('statistics', ['getAppStatistics']),
    ...mapGetters('authentication', ['getCurrentUser']),
    ...mapGetters('statistics', [
      'getAppStatistics',
      'getAverageDailyHoursTransactions',
      'getGlobalStatistics',
      'getGlobalBlueMediaDonationProcess',
      'getGlobalAvgPaymentTime'
    ]),
    ...mapGetters('statistics/googleAnalytics', ['getGlobalOS', 'getGlobalPhonesModels']),
    paymentProcessChartData() {
      return [this.blueMediaProcessChartSettings(paymentOperatorsTitles.blueMedia)]
    },
    getRowJustify() {
      return this.isTransactionalHoursTab ? 'center' : 'end'
    },
    tooltipMessage() {
      const {
        revenue,
        transactionsCount,
        transactionsHours,
        amountsTypes,
        phonesModels,
        os,
        paymentsProcess,
        avgPaymentTime
      } = this.tabsText

      if (this.isRevenueTab) return revenue.tooltip
      else if (this.isTransactionalCountTab) return transactionsCount.tooltip
      else if (this.isTransactionalHoursTab) return transactionsHours.tooltip
      else if (this.isOSTab) return os.tooltip
      else if (this.isUsersPhonesModelsTab) return phonesModels.tooltip
      else if (this.isPaymentOperatorTab) return paymentsProcess.tooltip
      else if (this.isAvgPaymentTimeTab) return avgPaymentTime.tooltip

      return amountsTypes.tooltip
    },
    isRevenueTab() {
      return this.activeTab === 0
    },
    isTransactionalCountTab() {
      return this.activeTab === 1
    },
    isTransactionalHoursTab() {
      return this.activeTab === 2
    },
    isAmountsTypesTab() {
      return this.activeTab === 3
    },
    isOSTab() {
      return this.activeTab === 4
    },
    isUsersPhonesModelsTab() {
      return this.activeTab === 5
    },
    isPaymentOperatorTab() {
      return this.activeTab === 6
    },
    isAvgPaymentTimeTab() {
      return this.activeTab === 7
    }
  },
  methods: {
    ...mapMutations('components', ['setAllFoundationsTab']),
    tabText(key) {
      return this.tabsText[key].text
    },
    blueMediaProcessChartSettings(operator) {
      return {
        ...this.funnelChartOptions,
        ...this.funnelChart,
        operator
      }
    },
    prepareAmountTypesChartData() {
      const {amountsTypes} = this.getGlobalStatistics || {}
      this.amountsTypesChartData = amountsTypes ? [amountsTypesHeaders, ...Object.entries(amountsTypes)
          .map(([key, value]) => [amountTypes[key], value])] : []
    },
    changeDate(key, value) {
      this.datePickers[key] = value // FROM MIXIN
      this.prepareTransactionsHoursChartData()
    },
    changeAvgPaymentTimeDate(key, value) {
      this.avgPaymentTimeDatePickers[key] = value // FROM MIXIN
      this.prepareAvgPaymentTimeChartData()
    },
    changeAvgPaymentTimePickerType(type) {
      this.avgPaymentTimePickerType = type
      this.prepareAvgPaymentTimeChartData()
    },
    prepareTransactionsHoursChartData() {
      const data = {...this.hoursObject}
      Object.entries(this.filterDates(this.getAverageDailyHoursTransactions)).forEach(([, hours]) => {
        Object.entries(hours).forEach(([key, value]) => {
          data[key] += value
        })
      })

      this.setTransactionalHoursChartData(data)
    },
    indexOfCurrency(balances, searchedCurrency) {
      return balances.findIndex(({currency}) => currency === searchedCurrency)
    },
    prepareTransactionsCountChartData() {
      this.transactionalCountChartData = [transactionsChartHeaders, ...this.getAppStatistics
          .map(({ // MAPPING ALL STATISTICS OBJECT TO SHORT OBJECT WITH LINK AND TRANSACTIONS AMOUNT OBJECT
                  link,
                  transactionsAmount
                }) => {
            const {byGlobalCollection, byStickers} = transactionsAmount
            return [ // UNPACKING TRANSACTIONS AMOUNT OBJECT
              link,
              byGlobalCollection.success,
              byStickers.success,
              byGlobalCollection.other,
              byStickers.other // PACKING DATA TO ARRAY [LINK, SUCCESS TRANSACTIONS ON COLLECTION, SUCCESS TRANSACTIONS ON STICKERS, OTHER TRANSACTIONS ON COLLECTION, OTHER TRANSACTIONS ON STICKERS]
            ]
          })
          .filter(([, first, second]) => first || second).sort(([, firstSuccessGlobal, firstSuccesStickers], [, secondSuccessGlobal, secondSuccesStickers]) => { // FILTERING DESCENDING BY ALL SUCCESS TRANSACTIONS
            const first = firstSuccessGlobal + firstSuccesStickers
            const second = secondSuccessGlobal + secondSuccesStickers
            return this.$numberSortDesc(first, second)
          })]
    },
    prepareRevenueChartData() {
      this.revenueChartData = [revenueChartHeaders, ...this.getAppStatistics
          .map(({link, balances}) => {
            return [
              link, // MAPPING STATISTICS OBJECT TO FORMAT [LINK, PLN, EUR, USD, GBP]
              ...currencies.map(element => {
                const index = this.indexOfCurrency(balances, element)
                return balances?.[index]?.amount || 0
              })
            ]
          })
          .filter(({1: amount}) => !!amount)]
    },
    prepareOSChartData() {
      if (!this.getGlobalOS) return

      this.osChartData = [
        osChartHeaders,
        ...this.getGlobalOS.rows.map(({dimensionValues: [os], metricValues: [amount]}) => [
          os.value,
          parseInt(amount.value)
        ])
      ]
    },
    prepareAvgPaymentTimeChartData() {
      const data = this.filterAvgPaymentTimeDates(this.getGlobalAvgPaymentTime)

      this.avgPaymentTimeChartData = this.prepareAveragePaymentTimeData(data, this.avgPaymentTimePickerType)
    },
    preparePhonesModelsChartData() {
      if (!this.getGlobalPhonesModels) return

      const {rows} = this.getGlobalPhonesModels, phonesModels = {}

      rows.map(({dimensionValues: [{value: deviceCategory}, ...restData], metricValues: [amount]}) => {
        const models = restData.filter(({value}) => value !== this.phonesModelsNotDefined).map(({value}) => value)
        if (!this.phonesModelsToExclude.includes(deviceCategory)) {
          phonesModels[models.join(' ')] = parseInt(amount.value)
        }
      })

      this.usersPhonesModelsData = [usersPhonesModelsHeaders, ...Object.entries(phonesModels)]
    },
  },
  watch: {
    getGlobalStatistics() {
      this.prepareAmountTypesChartData()
    },
    activeTab(e) {
      this.setAllFoundationsTab(e)
    },
    getGlobalOS() {
      this.prepareOSChartData()
    },
    getGlobalPhonesModels() {
      this.preparePhonesModelsChartData()
    },
    getGlobalBlueMediaDonationProcess() {
      this.prepareBlueMediaDonationProcessChart(this.getGlobalBlueMediaDonationProcess)
    }
  }
}
</script>
