<template>
  <layout-dashboard :title="title">
    <v-container fluid class="pa-0 ma-0 fill-container-height">
      <v-dialog
          v-model="dialog"
          width="500"
          height="500"
      >
        <v-card class="pa-8">
          <span v-for="(item, index) in Object.keys(contactHeaders)" :key="index" class="pb-4" >
            <v-card-subtitle v-if="chosenContact[item]">
                          {{ contactHeaders[item] }}{{ chosenContact[item] }}
            </v-card-subtitle>

          </span>
          <v-row v-if="!Object.keys(chosenContact).length">{{ noData }}</v-row>
        </v-card>

      </v-dialog>
      <v-row justify="center" class="pa-0 ma-0 mt-4">
        <v-col cols="10" sm="11" class="pa-0 ma-0">
          <statistics-charts v-if="areChartsReady"></statistics-charts>
          <v-card v-else>
            <v-card-subtitle>
              <loader-animation :size="15"></loader-animation>
              <span class="ml-2">{{ texts.loadingCharts }}</span>
            </v-card-subtitle>
          </v-card>
          <v-card>
            <v-data-table
                :headers="headers"
                :items="getAppStatistics"
                :items-per-page="itemsPerPage"
                :custom-sort="customSort"
                class="ma-0 mb-12 pa-0 mt-4"
                id="statistics-data-table"
            >
              <template #item.link="{item}">
                <span class="font-weight-bold">{{ item.link }}</span>
              </template>
              <template #item.information="{item}">

                <v-icon @click="getContact(item.id)" :disabled="getAppStatisticsFetching">
                  mdi-information-outline
                </v-icon>
              </template>
              <template #item.balances="{item}">
                <v-row v-for="(element, index) in item.balances" :key="index" class="mx-0 my-2 px-0">
                  <div>
                    <span>{{ `${element.currency.toUpperCase()}:` }}&nbsp;</span>
                    <span>{{ element.amount.toLocaleString() }}</span>
                  </div>
                </v-row>
              </template>
              <template #item.more="{item}">
                <router-link :to="`${linkPath}${item.link}`">
                  <v-btn class="text-none white--text" :color="$mainColor">{{ texts.buttonMore }}</v-btn>
                </router-link>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </layout-dashboard>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import StatisticsCharts from "@/components/dashboard/statistics/Charts";
import LoaderAnimation from "@/components/global/LoaderAnimation";
import DataTableMixin from "@/mixins/components/data-tables"

export default {
  name: "Statistics",
  components: {LoaderAnimation, StatisticsCharts},
  mixins: [DataTableMixin],
  data: () => ({
    noData: 'No data!',
    chosenContact: {},
    contactHeaders: {
      organisationName: 'Organisation name: ',
      email: 'Email: ',
      additionalInfo: 'Additional info: ',
      address: 'Address: ',
      city: 'City: ',
      country: 'Country: ',
      krs: 'Krs: ',
      nip: 'Nip: ',
    },
    dialog: false,
    title: 'Statistics',
    linkPath: '/dashboard/statistics/collection-evidence/',
    itemsPerPage: 10,
    headers: [
      {text: 'Collection link', value: 'link', sortable: false},
      {text: '', value: 'information', sortable: false},
      {text: 'Custom link', value: 'customLink', sortable: false},
      {
        text: 'Success statuses transactions [Stickers + global collection]',
        value: 'transactionsAmount.allTransactions.success'
      },
      {
        text: 'Other statuses transactions [Stickers + global collection]',
        value: 'transactionsAmount.allTransactions.other'
      },
      {text: 'Stickers amount', value: 'tags.length'},
      {text: 'Revenue', value: 'balances', sortable: true},
      {text: '', value: 'more', sortable: false},
    ],
    texts: {
      buttonMore: 'More info',
      loadingCharts: 'Fetching data for charts'
    },
    loadingMessage: 'Fetching statistics data'
  }),
  computed: {
    ...mapGetters('statistics', ['getAppStatistics', 'getAppStatisticsFetching', 'getGlobalStatistics']),
    loaderMessage() {
      return !this.getAppStatistics.length ? this.loadingMessage : ''
    },
    areChartsReady() {
      return !this.getAppStatisticsFetching && Object.keys(this.getGlobalStatistics).length
    },
    sortedItems() {
      const items = [...this.getAppStatistics]
      return items.sort((a, b) => {
        const first = this.counterHelper(a.balances.map(({amount}) => amount))
        const second = this.counterHelper(b.balances.map(({amount}) => amount))
        return this.sortHelperNumbers(first, second)
      })
    }
  },
  async beforeMount() {
    this.setLoader({visible: !this.getAppStatistics.length, message: this.loaderMessage})
    await this.globalAmountsTypes()
  },
  methods: {
    ...mapMutations('components', ['setLoader']),
    ...mapActions('statistics', ['globalAmountsTypes']),
    ...mapActions('users', ['fetchContactInfo']),
    async getContact(id){
      this.chosenContact = await this.fetchContactInfo(id)
      this.dialog = !!this.chosenContact
    },
    counterHelper(arr) {
      arr.reduce((old, next) => old + next, 0)
    },
    sortHelperNumbers(first, second) {
      return first === second ? 0 : (first > second) ? -1 : 1
    },
    transactions(item, key) {
      return item.transactionsAmount.allTransactions[key]
    }
  },
  watch: {
    getAppStatistics({length}) {
      this.setLoader({visible: !length, message: this.loaderMessage})
    }
  }
}
</script>
<style lang="scss">

.v-data-table__wrapper {
  tr:hover {
    background-color: white !important;
  }
}

#statistics-data-table {
  .v-data-table__wrapper {
    thead {
      th {
        font-size: 12px !important;
      }
    }

    .v-data-table__mobile-row:nth-child(3) {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 600px) {
    tr {
      min-height: 350px !important;
    }
  }
}


</style>

